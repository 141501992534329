import { BufferedRange } from './BasePlayer';

export type PlayerType = 'webos' | 'tizen' | 'browser';

export const timeout = async (
  prom: Promise<any>,
  time: number
): Promise<void> => {
  let timerId: number;
  return await Promise.race([
    prom,
    new Promise((resolve, reject) => (timerId = setTimeout(reject, time))),
  ]).finally(() => clearTimeout(timerId));
};

export const parseHtmlDurationRange = (
  buffered: TimeRanges
): BufferedRange[] => {
  return Array(buffered?.length)
    .fill(0)
    .map((_, i) => ({
      start: buffered.start(i),
      end: buffered.end(i),
    }));
};

export const pickPlayerType = (): PlayerType => {
  if (navigator.userAgent.match(/Tizen/) != null) {
    return 'tizen';
  } else if (
    navigator.userAgent.match(/Web0S/) != null ||
    navigator.userAgent.match(/WebOS/) != null
  ) {
    return 'webos';
  }
  return 'browser';
};

export const sleep = async (n: number): Promise<void> => {
  await new Promise((resolve) => setTimeout(resolve, n));
};

export class Logger {
  private readonly tag: string;

  constructor(tag: string) {
    this.tag = tag;
  }

  public log(...args: any[]): void {
    console.log(`[${this.tag}]`, ...args);
  }

  public error(...args: any[]): void {
    console.error(`[${this.tag}]`, ...args);
  }
}

export const waitFor = async (target: any, type: string): Promise<any> => {
  return await new Promise<any>((resolve) => {
    target.addEventListener(type, resolve, { once: true });
  });
};

export const uInt8ArrayToString = (array: Uint8Array): string =>
  String.fromCharCode.apply(null, array as any);

export const base64EncodeUint8Array = (input: Uint8Array): string => {
  return btoa(uInt8ArrayToString(input));
};

export const base64DecodeUint8Array = (input: string): Uint8Array => {
  return Uint8Array.from(atob(input), (c) => c.charCodeAt(0));
};
