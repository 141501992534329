export type DrmType = 'widevine' | 'fairplay' | 'clearkey';

export interface Drm {
  type: DrmType;
  data: {
    certificateUrl?: string;
    licenseUrl: string;
  };
  headers?: any;
}

export interface Quality {
  id: string;
  width?: number;
  height?: number;
  bitrate?: number;
}

export interface SubtitleTrack {
  index: number;
  language: string;
}

export interface BufferedRange {
  start: number;
  end: number;
}

export interface Size {
  width: number;
  height: number;
}

export interface PlayerEvent {
  key: string;
  type:
    | 'completed'
    | 'play'
    | 'pause'
    | 'stop'
    | 'bufferingStart'
    | 'bufferingEnd'
    | 'bufferingUpdate'
    | 'initialized'
    | 'error'
    | 'updateVideoSize';
  buffered?: BufferedRange[];
  bitrate?: number;
  duration?: number;
  size?: Size;
}

export type PlayerEventCallback = (event: PlayerEvent) => void;

export abstract class BasePlayer {
  abstract isInitialized(): boolean;

  abstract viewElement(): HTMLElement;

  abstract init(): Promise<void>;

  abstract destroy(): Promise<void>;

  abstract setSrc(url: string, drm?: Drm, useSmartLib?: boolean): Promise<void>;

  abstract play(): Promise<void>;

  abstract pause(): Promise<void>;

  abstract stop(): Promise<void>;

  abstract seekTo(position: number): Promise<void>;

  abstract position(): Promise<number>;

  abstract setVolume(volume: number): Promise<void>;

  abstract setAudioTrack(
    index: number,
    language: string,
    languageCode: string
  ): Promise<void>;

  abstract setSubtitleTrack(index: number, language: String): Promise<void>;

  abstract getSubtitleTracks(): Promise<SubtitleTrack[]>;

  abstract setQuality(bitrate?: number, width?: number, height?: number): any;

  abstract onEvent(listener: PlayerEventCallback): void;
}
